.session-button{
    height: 40px;
  width: auto;
  padding: 8px 20px;
  border-radius: 4px;
  min-width: 100px;
  font-size: 14px;
  border: 0px;
  letter-spacing: 0.5px;
  cursor: pointer;
   background-color: #2477AB;
   color: white;
   margin-left: 10px;
font-family: 'DiscoverSans-Medium';
  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}

.session-conts{
    margin-bottom: 40px;
}

.session-label{
    font-size: 16px;
    font-family: "DiscoverSans-bold";
}

.session-desc{
    font-size: 14px;
    font-family: "DiscoverSans-medium";
    margin: 20px 0 0 0;
}