/* Footer */
.footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    font-weight: 500;
    font-size: 12px;
    height: 30px;
    background-color: #fff;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    color: gray;
    font-family: "DiscoverSans-Medium";
}