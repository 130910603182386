.login_page {
  position: relative;
}


.login_form {  
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 50vw;
  overflow: auto;
  height: calc(100vh - 94px);
  margin-top: 64px;
}

.form_contains{
  height: 100%;
    align-items: center;
    display: flex;
}

.login_page_poster {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  background: radial-gradient(50% 50% at 50% 50%, rgb(22, 29, 83) 0%, rgb(31, 31, 124) 100%);
  width: 50%;
  background-color: rgba(255, 255, 255, 1);
}

.login_page_poster p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  font-size: 2rem;
  color: whitesmoke;
  font-weight: 600;
  text-align: center;
}

.login_page_poster img {
  width: 100%;
  opacity: 0.6;
  height: inherit;
  background-attachment: fixed !important;
}

.login-form-button {
  width: 100%;
  color: #fff !important;
  font-weight: 500;
}

.fill {
  background-color: #4239ad !important;
  color: #fff !important;
}

.login-form-new-button {
  width: 100%;
  color: #fff !important;
  font-weight: 500;
  background-color: #4239ad !important;
}

.logo_png {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.discover-logo {
  width: fit-content;
  height: 22px;
}

.w {
  width: 25vw !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  height: 100%;
}

.notificationBg {
  width: 100%;
  height: auto;
  position: relative;
}

.my-message {
  width: 300px;

}

.success-popup {
  background-color: rgb(110, 241, 110);
}

.error-popup {
  background-color: rgb(210, 89, 89);
}

.ant-message-error {
  background-color: white;
  color: rgb(242, 85, 85);
  font-size: 16px;
  height: 30px;
  width: 100%;
}

.ant-message-success {
  background-color: white;
  color: rgb(55, 216, 55);
  font-size: 16px;
  height: 30px;
  width: 100%;
}


.signInText {
  font-size: 2rem;
  color: black;
  font-weight: 600;
  font-family: sans-serif;
  text-align: center;
}

@media (max-width: 768px) {
  .login_form {
    width: 100%;
  }

  .login_page_poster {
    display: none;
  }

  .signInText {
    font-size: 1.5rem;
  }
}

/* Row */
.row_1 {
  /* border-bottom: 3px solid #ec6c2a; */
  z-index: 5;
  position: fixed;
  left: 0;
  background-color: #fff !important;
  right: 0;
}

/* col */
.col_1 {
  padding: 0px 20px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  z-index: 5;
}

.logo_png {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.logo_png span {
  background-color: #ec6c2a;
  color: #fff;
  padding: 2px 10px;
  border-radius: 8px;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}