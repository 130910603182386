.myprofile-cont{
    margin: 20px;
}

.myprofile-title{
    font-size: 18px;
    font-family: 'DiscoverSans-bold';
}

.myprofile-content{
    margin-top: 20px;
}

.myprofile-conts{
    min-width: 400px;
    width: 4%;
}

.pwd-circle{
    font-size: 12px !important;
    color: #2477AB;
}