@font-face {
  font-family: 'DiscoverSans-Bold';
  /* src: url('DiscoverSans-Bold?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-Bold.eot'),
       url('DiscoverSans-Bold.woff2') format('woff2'), 
       url('DiscoverSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-BoldItalic';
  /* src: url('DiscoverSans-BoldItalic?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-BoldItalic.eot'),
       url('DiscoverSans-BoldItalic.woff2') format('woff2'), 
       url('DiscoverSans-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-Book';
  /* src: url('DiscoverSans-Book?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-Book.eot'),
       url('DiscoverSans-Book.woff2') format('woff2'), 
       url('DiscoverSans-Book.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-Extrabold';
  /* src: url('DiscoverSans-Extrabold?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-Extrabold.eot'),
       url('DiscoverSans-Extrabold.woff2') format('woff2'), 
       url('DiscoverSans-Extrabold.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-ExtraboldItalic';
  /* src: url('DiscoverSans-ExtraboldItalic?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-ExtraboldItalic.eot'),
       url('DiscoverSans-ExtraboldItalic.woff2') format('woff2'), 
       url('DiscoverSans-ExtraboldItalic.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-Italic';
  /* src: url('DiscoverSans-Italic?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-Italic.eot'),
       url('DiscoverSans-Italic.woff2') format('woff2'), 
       url('DiscoverSans-Italic.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-Light';
  /* src: url('DiscoverSans-Light?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-Light.eot'),
       url('DiscoverSans-Light.woff2') format('woff2'), 
       url('DiscoverSans-Light.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-LightItalic';
  /* src: url('DiscoverSans-LightItalic?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-LightItalic.eot'),
       url('DiscoverSans-LightItalic.woff2') format('woff2'), 
       url('DiscoverSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-Medium';
  /* src: url('DiscoverSans-Medium?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-Medium.eot'),
       url('DiscoverSans-Medium.woff2') format('woff2'), 
       url('DiscoverSans-Medium.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-MediumItalic';
  /* src: url('DiscoverSans-MediumItalic?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-MediumItalic.eot'),
       url('DiscoverSans-MediumItalic.woff2') format('woff2'), 
       url('DiscoverSans-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-Semibold';
  /* src: url('DiscoverSans-Semibold?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-Semibold.eot'),
       url('DiscoverSans-Semibold.woff2') format('woff2'), 
       url('DiscoverSans-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-SemiboldItalic';
  /* src: url('DiscoverSans-SemiboldItalic?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-SemiboldItalic.eot'),
       url('DiscoverSans-SemiboldItalic.woff2') format('woff2'), 
       url('DiscoverSans-SemiboldItalic.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-Thin';
  /* src: url('DiscoverSans-Thin?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-Thin.eot'),
       url('DiscoverSans-Thin.woff2') format('woff2'), 
       url('DiscoverSans-Thin.woff') format('woff');
}

@font-face {
  font-family: 'DiscoverSans-ThinItalic';
  /* src: url('DiscoverSans-ThinItalic?#iefix') format('embedded-opentype'); */   /*commented due to module not found error  */
  src: url('DiscoverSans-ThinItalic.eot'),
       url('DiscoverSans-ThinItalic.woff2') format('woff2'), 
       url('DiscoverSans-ThinItalic.woff') format('woff');
}

