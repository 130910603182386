

.header-right{
    column-gap: 20px !important;
}
.user-name{
    color: white;
    font-family: 'DiscoverSans-Medium';
    font-size: 1rem;
}

.user-icon{
    height: 1.875rem;
    width:  1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 20px;
    background-color: #F6921E;
    cursor: pointer;
}
.user-icon>img{
    height: 100%;
}

.burger-icon{
    /* margin: 3px; */
    background-color: #23233F;
    height: 49px;
    width: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-radius: 50px;
    cursor: pointer;
    
}

.burger-icon>img{
    height: 30%;
}
.discover-logo{
    height: 58px !important;
    width: 124px !important;
}

.header-label{
    font-family: 'DiscoverSans-Semibold' !important;
    font-size: 1.18rem !important;
}

.header-section{    
  border-bottom: 5px solid !important;
  border-image: linear-gradient(to right, #EC7028, #FBB016) 1;
  padding: 0px 0px 0px 8px !important;
}

.header-section-bgd-grad{
    background: linear-gradient(
        to right,
        #23233F 0%,
        #23233F 99%,
        #fff 99%,
        #fff 100%
      );
}

.header-section-bgd-full{
    background-color: #23233F;
}

.burger-icon-container{
 height: 58px;
 width: 58px;
 padding: 4px;
 background: linear-gradient(175deg, #EB6B29, #faad17);
 border-radius: 50px;
}

.logout-icon{
    color:#ec6c2a;
    font-size: 18px;
}


@media(max-width:2560px){
    .header-section-bgd-grad{
        background: linear-gradient(
        to right,
        #23233F 0%,
        #23233F 98.7%,
        #fff 98.7%,
        #fff 100%
      );
    }
}
@media(max-width:1800px){
    .header-section-bgd-grad{
        background: linear-gradient(
        to right,
        #23233F 0%,
        #23233F 98.1%,
        #fff 98.1%,
        #fff 100%
      );
    }
}

@media(max-width:1536px){
    .header-section-bgd-grad{
        background: linear-gradient(
        to right,
        #23233F 0%,
        #23233F 98%,
        #fff 98%,
        #fff 100%
      );
    }
}
@media(max-width:1280px){
    .header-section-bgd-grad{
        background: linear-gradient(
        to right,
        #23233F 0%,
        #23233F 97.5%,
        #fff 97.5%,
        #fff 100%
      );
    }
}
@media(max-width:1024px){
    .header-section-bgd-grad{
        background: linear-gradient(
        to right,
        #23233F 0%,
        #23233F 97%,
        #fff 97%,
        #fff 100%
      );
    }
}

@media(max-width:768px){
    .header-section-bgd-grad{
        background: linear-gradient(
        to right,
        #23233F 0%,
        #23233F 96%,
        #fff 96%,
        #fff 100%
      );
    }
}

@media(max-width:640px){
    .header-section-bgd-grad{
        background: linear-gradient(
        to right,
        #23233F 0%,
        #23233F 95%,
        #fff 95%,
        #fff 100%
      );
    }
    .discover-logo{
        height: 2.4rem !important;
    }
    .header-label{
        font-size: .9rem !important;
    }
    .user-name{
        font-size: .8rem;
    }
    .user-icon{
        height: 1.5rem;
        width:  1.5rem;
        background: none;
    }
    .header-right {
        column-gap: 1rem !important;
    }
    .notification-icon {
        font-size: 1.25rem !important;
    }
}
@media(max-width:475px){

    .header-section-bgd-grad{
        background: linear-gradient(
        to right,
        #23233F 0%,
        #23233F 93%,
        #fff 93%,
        #fff 100%
      );
    }
    .header-label{
        font-size: .7rem !important;
    }
    .user-name{
        font-size: .6rem;
    }
    .user-icon{
        /* height: 1.5rem; */
        /* width:  1.5rem; */
        background: none;
    }
    .notification-icon{
        height: 1rem !important;
        width: 1rem !important;
        margin-top: 3px;
    }
    .header-right {
        column-gap: .4rem !important;
    }
}

.burger-icon{
    color: white;
}

.head-help-icon{
    color: white;
    font-size: 1.8rem;
    cursor: pointer;
}