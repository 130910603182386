/********************************************** NOTIFICATION STYLE ******************************************************** */

.notification-container {
    background-color: #373972;
    /* min-height: 122px; */
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
    /* overflow: hidden; */
    margin: 20px 30px 12px 20px;
    border-radius: 10px;

}

.notification-label label {
    font-family: 'DiscoverSans-Semibold';
    font-size: 20px;
    padding: 19px 24px 19px 35px;
    color: white
}

.notification-label {
    height: 60px;
}

/* **************************************************END *********************************************** */


/* *************************************NOTIFICATION DIV STYLE ******************************************** */

.notification-div-container {

    border-radius: 12px;
    height: 178px;
    /* min-width: 500px; */
    padding: 20px;
    background-color: white;
    cursor: pointer;
}

.notification-div-container:hover {
    background-color: #eceaea;
    border: 1px solid #EC6C2A;
}

.notification-div-container:visited {
    background-color: white;
}

.notification-div-container h5 {
    font-family: 'DiscoverSans-bold';
    font-size: 18px;
    line-height: 25px;
}

.notification-div-container p {
    font-family: 'DiscoverSans-Medium';
    font-size: 16px;
    line-height: 22px;
}


/* ****************************************END ******************************************************** */


/* NOTIFICATION MODAL STYLE */

.notification-modal-container label {
    font-family: 'DiscoverSans-Medium';
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.notification-modal-container p {
    font-family: 'DiscoverSans-Medium';
    line-height: 18px;
    font-size: 13px;
    font-weight: 400;
    color: #2D2D2D;
}

.close-button {
    width: 102px;
    height: 37px;
    border-radius: 3px;
    border: none;
    font-family: 'DiscoverSans-Medium';
    font-weight: 500;
    line-height: 19px;
    font-size: 14px;
    color: #FFFFFF;
    background-color: #EC6C2A;
}

/* END */

/* DOWNLOAD FILE  */

.download-container {
    height: 43px;
    border-radius: 6px;
    background-color: #FFF8F5;
    padding: 0 10px 2px 10px;
}

.file-name {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.file-size {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 10px;
    color: gray;
}

.description-icon {
    font-size: 26px;
    padding-bottom: 4px;
}

.arrow-downward-icon {
    font-size: 26px;
    color: #EC6C2A;
    cursor: pointer;
    padding-bottom: 4px;
}

.notification-list {
    overflow: hidden;
    height: 95%;
    padding: 0px 35px 0px 35px;
}

/* custom  */
/* .notification-cont {
    height: calc(100% -40px) !important;
} */

/* scroll container  */
/* .scroll-container {
    height: 88% !important;
} */

.scroll-icons {
    display: grid;
    gap: 10px;
    position: absolute;
    top: 50%;
    right: -20px !important;
    width: 25px;
    background-color: #373972;
    border-radius: 8px;
    padding: 30px 1px 30px 1px;
    transform: translateY(-50%);
}

.scroll-btn {
    display: inline-block;
    height: 4px;
    width: 4px;
    background-color: #FFF8F5;
    margin-left: 10px;
    border: none;
    border-radius: 50px;
}

.scroll-icons>img {
    height: 10px;
    margin-left: 7px;
    cursor: pointer;
}

/* END */