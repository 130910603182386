.change-password-container {
    justify-items: center;
    align-items: center;
    gap: 0px !important;
    font-family: DiscoverSans-Medium;
    margin: 0 20px;
    max-height: 100%;
}
.single-column{
    display: grid !important;
    grid-template-columns: 1fr;
}
.single-column>.condition-section{
    margin: 10px 10px 10px 32px;
}
.double-column{
    display: grid !important;
    grid-template-columns: 1fr 1fr;
}

.double-column>.condition-section{
    margin: 6rem 1rem 1rem 1rem;
}

.form-section {
    order: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-section {
    margin-top: 20px;
    order: 3;
    margin-top: 20px;
}

.condition-section {
    text-align: left;
    order: 2;
    height: fit-content;
    margin: 0;
    padding: 0;
    
}
.form-input{
    min-width: 400px !important;
}

@media (max-width:900px){
    .double-column{
        grid-template-columns: 1fr;
    }
    .double-column>.condition-section{
        margin: 0px 0px 0px 10px;
    }
}

@media (max-width:500px) {
    .change-password-container{
        width: 350px !important;
    }
    .form-input{
        min-width: 300px !important;
    }
    
    .cancel-button,
    .condition-section,
    .change-password-button{
    width: 300px !important;
    margin: 5px !important;
    }
}