.scroll-container {
    height: 100%;
    width: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 15px 0px 20px 0px !important;
}

.scroll-container::-webkit-scrollbar {
    display: none;
}