.notification_col {
  width: 40%;
  display: flex;
  flex-direction: column;
  background: #373972;
  gap: 10px;
  height: 100vh;
  position: fixed;
  top: 48px;
  overflow-y: auto;
  right: 0px !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  height: 100%;
}
/* ant card */
.ant-card.ant-card-bordered.notify {
  width: 28vw;
}
.notify h5 {
  font-size: 16px;
}
.notify p {
  margin-bottom: 0px;
  font-size: 12px;
}
.notify img {
  width: 70px;
  height: 5%;
  position: relative;
  top: -20px;
}
.ant-card-body.notify {
  padding: 0px !important;
  width: 100%;
}
.notify.ant-card .ant-card-body {
  padding: 10px 24px;
  border-radius: 0 0 8px 8px;
}
.notify_col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.notification_col .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 2px;
  position: sticky;
  top: 0px;
  z-index: 5;
}
.header h5 {
  font-size: 16px !important;
  padding: 0px 20px;
}

/* Application list side */
.Apps_lists {
  width: 60%;
  position: relative;
  top: 110px;
  left: 9vw;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.Apps_lists .dg{
  position: relative;
  bottom: 20px;
  color: #ec6c2a;
  font-size: 20px;
  font-weight: 500;
}
.select {
  position: absolute;
  top: 45px;
  height: 55px;
  display: flex;
  align-items: center;
  padding: 0px 24px;
}
.Apps_lists .lists {
  display: grid;
  column-gap: 10px;
  row-gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;

  
  display: flex;
  margin: 50px;
}

.lists{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 50px;
}

.app_card .ant-card {
  /* border: 1px solid #c8c8c84e; */
  min-width: 9.5vw;
  min-height: 7vw;
  left: 0px;
  margin: 6px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* padding: 10px; */
}
.app_card .ant-card-body,
.admin_card .ant-card-body {
  padding: 0px !important;
}
.app_card .ant-card:hover {
  background-color: #e0e0e033;
}
.span_description {
  font-size: 9px;
}
.span_name {
  font-family: 'DiscoverSans-Medium';
  font-size: 13px;
  font-weight: 700;
  background-color: #23233F;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;
  padding: 3px;
}

.app_card .app_list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #EB6B29;
  border-radius: 15px 15px 0px 0px;
}

.admin_card .ant-card {
  height: 15vh;
  width: 20vw;
  border: 1px solid #c8c8c84e;
  display: flex;
  justify-content: center;
}
.admin_card .ant-card:hover {
  background-color: #e0e0e033;
}
.admin_card .ant-card div .admin_img {
  height: 40px;
}
.admin_card .ant-card div {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* Header */
.navbar-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  border-bottom: 3px solid #ec6c2a;
  z-index: 20;
}
.ant-layout {
  background: #fff !important;
}
.navbar-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 46px;
  padding-left: 17px;
}
.navbar-wrapper span {
  color: #ec6c2a;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
img.logo {
  height: 30px;
}
.btn_color {
  height: 30px !important;
  position: fixed;
  right: 1%;
  
  background-color: #ec6c2a !important;
}

/* Row */
.row_1 {
  /* border-bottom: 3px solid #ec6c2a; */
  z-index: 5;
  position: fixed;
  left: 0;
  background-color: #fff !important;
  right: 0;
}

/* col */
.col_1 {
  padding: 0px 20px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  z-index: 5;
}


/* custom  */
hr{
  margin: -1px;
  color: transparent;
}

.app-list-item-card {
  border: 0px solid #f0f0f0;
}

.app_list img{
  height: 100px;
  width: 140px;
  margin: 12px 25px;
  /* background-color: #313E95; */
  /* border-radius: 50px; */
}
.notification-cont{
  width: 100%;
}
.main-container{
  height: calc(100vh - 103px);
  overflow: auto;
}
.list-container{
 height: 100% !important;
}

.apps-container{
  height: 100%;
  overflow-y: auto;
}